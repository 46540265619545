<template>
  <div class="event-details-container">
    <h1>Modifier l'évènement</h1>
    <p v-if="updateMessage" class="success">{{ updateMessage }}</p>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>

    <div class="image-wrapper">
      <img v-if="event.imageUrl" :src="event.imageUrl" alt="Event Image" class="event-image">
      <input type="file" @change="handleImageChange" class="form-control">
    </div>
    <p v-if="imageError" class="error">L'image est requise</p>
    <div v-if="isFieldEdited('imageUrl') && originalEvent.imageUrl && !originalEvent.imageUrl.startsWith('http')">
      <span>édité par {{ getEditorName('imageUrl') }}</span>
      <button @click="revertChange('imageUrl')" class="btn btn-secondary mt-2">Revenir</button>
    </div>

    <div class="form-group">
      <label class="form-label">Crédit image</label>
      <input type="text" v-model="event.imageCredit" class="form-control" placeholder="Ajouter le crédit image">
      <div v-if="isFieldEdited('imageCredit')">
        <span>édité par {{ getEditorName('imageCredit') }}</span>
        <button @click="revertChange('imageCredit')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Titre</label>
      <input type="text" v-model="event.title" class="form-control" placeholder="Ajouter le titre">
      <div v-if="isFieldEdited('title')">
        <span>édité par {{ getEditorName('title') }}</span>
        <button @click="revertChange('title')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Tags</label>
      <div class="tags">
        <span v-for="tag in event.tags" :key="tag" class="tag">
          {{ tag }}
          <button @click="removeTag(tag)">x</button>
        </span>
      </div>
      <input type="text" v-model="newTag" @keyup.enter="addTag" class="form-control mt-2" placeholder="Ajouter un tag">
      <div v-if="isFieldEdited('tags')">
        <span>édité par {{ getEditorName('tags') }}</span>
        <button @click="revertChange('tags')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="form-label">Début</label>
        <input type="datetime-local" v-model="event.startDate" :max="maxStartDate" class="form-control" placeholder="Début">
        <div v-if="isFieldEdited('startDate')">
          <span>édité par {{ getEditorName('startDate') }}</span>
          <button @click="revertChange('startDate')" class="btn btn-secondary mt-2">Revenir</button>
        </div>
      </div>

      <div class="col-md-6">
        <label class="form-label">Fin</label>
        <input type="datetime-local" v-model="event.endDate" :min="minEndDate" class="form-control" placeholder="Fin">
        <div v-if="isFieldEdited('endDate')">
          <span>édité par {{ getEditorName('endDate') }}</span>
          <button @click="revertChange('endDate')" class="btn btn-secondary mt-2">Revenir</button>
        </div>
      </div>
    </div>

    <h2>Horaires</h2>
    <div>
      <div v-for="(occurrence, index) in event.occurrences" :key="index" class="form-group">
        <p>
          {{ displayDate(occurrence[0]) }} - {{ displayDate(occurrence[1]) }}
          <button @click="deleteOccurrence(index)" class="btn btn-danger btn-sm">Supprimer</button>
        </p>
      </div>
      <div v-if="isFieldEdited('occurrences')">
        <span>Horaires édité par {{ getEditorName('occurrences') }}</span>
        <button @click="revertChange('occurrences')" class="btn btn-secondary mt-2">Revenir</button>
      </div>

      <div class="btn-group">
        <button @click="toggleAddOccurrenceForm" class="btn btn-secondary mt-2 me-2">Ajouter un horaire unique</button>
        <button @click="toggleAddRecurrenceForm" class="btn btn-secondary mt-2">Ajouter un horaire récurrent</button>
      </div>
      <div v-if="showAddOccurrence" class="mt-2">
        <input type="datetime-local" v-model="newOccurrenceStart" :max="maxOccurrenceStartDate" class="form-control" placeholder="Début">
        <input type="datetime-local" v-model="newOccurrenceEnd" :min="minOccurrenceEndDate" class="form-control mt-2" placeholder="Fin">
        <div class="btn-group">
          <button @click="addOccurrence" class="btn btn-secondary mt-2">Sauvegarder</button>
        </div>
        <p v-if="occurrencesErrorMessage" class="error">{{ occurrencesErrorMessage }}</p>
      </div>

      <div v-if="showAddRecurrence" class="mt-2">
        <label>Jours de la semaine</label>
        <div>
          <label v-for="day in daysOfWeek" :key="day">
            <input type="checkbox" v-model="selectedDays" :value="day" class="form-check-input">{{ day }}
          </label>
        </div>
        <label>Heure de début</label>
        <input type="time" v-model="newTimeStart" :max="maxRecurrenceStartTime" class="form-control">
        <label>Heure de fin</label>
        <input type="time" v-model="newTimeEnd" :min="minRecurrenceEndTime" class="form-control">
        <div class="btn-group">
          <button @click="generateAndAddOccurrences" class="btn btn-secondary mt-2">Générer des horaires récurrents</button>
        </div>
        <p v-if="recurrenceErrorMessage" class="error">{{ recurrenceErrorMessage }}</p>
        <p v-if="recurrenceDuplicateMessage" class="info">{{ recurrenceDuplicateMessage }}</p>
      </div>
    </div>

    <h2>Description</h2>
    <textarea v-model="event.description" class="form-control" placeholder="Ajouter une description"></textarea>
    <div v-if="isFieldEdited('description')">
      <span>édité par {{ getEditorName('description') }}</span>
      <button @click="revertChange('description')" class="btn btn-secondary mt-2">Revenir</button>
    </div>

    <h2>Lieu</h2>
    <div class="form-group">
      <label class="form-label">Nom du lieu</label>
      <input type="text" v-model="event.locationName" class="form-control" placeholder="Ajouter le nom du lieu">
      <div v-if="isFieldEdited('locationName')">
        <span>édité par {{ getEditorName('locationName') }}</span>
        <button @click="revertChange('locationName')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Adresse</label>
      <input type="text" v-model="event.locationStreet" class="form-control" placeholder="Ajouter la rue">
      <div v-if="isFieldEdited('locationStreet')">
        <span>édité par {{ getEditorName('locationStreet') }}</span>
        <button @click="revertChange('locationStreet')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="form-label">Code Postal</label>
        <input type="text" v-model="event.locationZipCode" class="form-control" placeholder="Ajouter le code postal">
        <div v-if="isFieldEdited('locationZipCode')">
          <span>édité par {{ getEditorName('locationZipCode') }}</span>
          <button @click="revertChange('locationZipCode')" class="btn btn-secondary mt-2">Revenir</button>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label">Ville</label>
        <input type="text" v-model="event.locationCity" class="form-control" placeholder="Ajouter la ville">
        <div v-if="isFieldEdited('locationCity')">
          <span>édité par {{ getEditorName('locationCity') }}</span>
          <button @click="revertChange('locationCity')" class="btn btn-secondary mt-2">Revenir</button>
        </div>
      </div>
    </div>

    <div class="btn-group">
      <button @click="updateCoordinates" class="btn btn-secondary mt-2">Trouver les coordonnées</button>
    </div>
      <p v-if="coordinatesMessage" :class="{ success: !coordinatesError, error: coordinatesError }">{{ coordinatesMessage }}</p>

    <div class="form-group">
      <label class="form-label">Région</label>
      <select v-model="event.area" class="form-select form-control">
        <option v-for="area in areas" :key="area" :value="area">{{ area }}</option>
      </select>
      <div v-if="isFieldEdited('area')">
        <span>édité par {{ getEditorName('area') }}</span>
        <button @click="revertChange('area')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <h2>Liens</h2>
    <label class="form-label">Site officiel</label>
    <input type="url" v-model="event.officialUrl" class="form-control" placeholder="Ajouter l'URL officielle">
    <div v-if="isFieldEdited('officialUrl')">
      <span>édité par {{ getEditorName('officialUrl') }}</span>
      <button @click="revertChange('officialUrl')" class="btn btn-secondary mt-2">Revenir</button>
    </div>

    <label class="form-label">Site de l'API</label>
    <input v-if="event.url.length > 0" type="url" v-model="event.url" class="form-control">
    <!-- <div v-if="isFieldEdited('url')">
      <span>édité par {{ getEditorName('url') }}</span>
      <button @click="revertChange('url')" class="btn btn-secondary mt-2">Revenir</button>
    </div> -->

    <h2>Informations supplémentaires</h2>
    <div class="form-group">
      <label class="form-label">Public</label>
      <input type="text" v-model="event.audience" class="form-control" placeholder="Ajouter une audience">
      <div v-if="isFieldEdited('audience')">
        <span>édité par {{ getEditorName('audience') }}</span>
        <button @click="revertChange('audience')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Réservations</label>
      <input type="text" v-model="event.access" class="form-control" placeholder="Ajouter réservation">
      <div v-if="isFieldEdited('access')">
        <span>édité par {{ getEditorName('access') }}</span>
        <button @click="revertChange('access')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Prix</label>
      <input type="text" v-model="event.price" class="form-control" placeholder="Ajouter prix">
      <div v-if="isFieldEdited('price')">
        <span>édité par {{ getEditorName('price') }}</span>
        <button @click="revertChange('price')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Transports</label>
      <input type="text" v-model="event.transport" class="form-control" placeholder="Ajouter les informations de transport">
      <div v-if="isFieldEdited('transport')">
        <span>édité par {{ getEditorName('transport') }}</span>
        <button @click="revertChange('transport')" class="btn btn-secondary mt-2">Revenir</button>
      </div>
    </div>

    <div class="mt-5 mb-5">
      <div class="form-check">
        <input type="checkbox" v-model="event.rampAccess" class="form-check-input" true-value="1" false-value="0">
        <label class="form-check-label">
          <i class="fa fa-wheelchair"></i> Accès Rampe
        </label>
      </div>
      <div class="form-check">
        <input type="checkbox" v-model="event.blindAccess" class="form-check-input" true-value="1" false-value="0">
        <label class="form-check-label">
          <i class="fa fa-blind"></i> Accès malvoyants
        </label>
      </div>
      <div class="form-check">
        <input type="checkbox" v-model="event.deafAccess" class="form-check-input" true-value="1" false-value="0">
        <label class="form-check-label">
          <i class="fa fa-deaf"></i> Accès malentendants
        </label>
      </div>
    </div>

    <div class="btn-group">
      <router-link :to="{ name: 'EventDetails', params: { id: event.id } }" class="btn btn-secondary mt-4">Page de l'évènement</router-link>
      <button @click="updateEvent" class="btn btn-primary">Mettre à jour</button>
    </div>
   
    <p v-if="updateMessage" class="success">{{ updateMessage }}</p>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>

  </div>
</template>

<script>
import axios from "../../axiosConfig.js";
import dateMixin from "../../mixins/dateMixin.js";

export default {
  mixins: [dateMixin],
  data() {
    return {
      event: {
        id: null,
        imageUrl: '',
        imageCredit: '',
        title: '',
        tags: [],
        startDate: '',
        endDate: '',
        occurrences: [],
        description: '',
        locationName: '',
        locationStreet: '',
        locationZipCode: '',
        locationCity: '',
        area: '',
        officialUrl: '',
        audience: '',
        access: '',
        price: '',
        transport: '',
        rampAccess: false,
        blindAccess: false,
        deafAccess: false,
        url: '',
        tracker: '',
        editedFields: []
      },
      originalEvent: {},
      newTag: '',
      minEndDate: '',
      maxStartDate: '',
      minOccurrenceEndDate: '',
      maxOccurrenceStartDate: '',
      minRecurrenceEndTime: '',
      maxRecurrenceStartTime: '',
      showOccurrences: false,
      occurrencesChanged: false,
      showAddOccurrence: false,
      showAddRecurrence: false,
      newOccurrenceStart: '',
      newOccurrenceEnd: '',
      daysOfWeek: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      selectedDays: [],
      newTimeStart: '',
      newTimeEnd: '',
      coordinatesMessage: '',
      coordinatesError: false,
      updateMessage: '',
      imageError: '',
      occurrencesErrorMessage: '',
      recurrenceDuplicateMessage: '',
      recurrenceErrorMessage: '',
      errorMessage: '',
      areas: [],
      user: this.$store.state.user,
      newImageFile: null
    };
  },
  computed: {
    isEventChanged() {
      return JSON.stringify(this.event) !== JSON.stringify(this.originalEvent);
    },
  },
  mounted() {
    this.fetchEvent();
    this.fetchAreas();
  },
  methods: {
    fetchEvent() {
      const eventId = this.$route.params.id;
      if (!eventId) {
        console.error('No event ID provided.');
        return;
      }
      axios.get(`events/${eventId}`, { withCredentials: true })
        .then(response => {
          const data = response.data;
          data.startDate = this.convertUnixToDateTimeLocal(data.startDate);
          data.endDate = this.convertUnixToDateTimeLocal(data.endDate);
          this.event = { ...data, editedFields: data.editedFields || [] };
          this.originalEvent = JSON.parse(JSON.stringify(this.event));
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    fetchAreas() {
      axios.get(`events`, { withCredentials: true })
        .then(response => {
          const data = response.data;
          const areaSet = new Set(data.map(event => event.area));
          this.areas = Array.from(areaSet);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    getEditorName(fieldName) {
      const edit = this.event.editedFields.find(e => e.field === fieldName);
      return edit ? edit.editorName : '';
    },
    handleImageChange(event) {
      this.newImageFile = event.target.files[0];
      this.uploadImage();
    },
    async uploadImage() {
      if (!this.newImageFile) {
        this.errorMessage = 'No image selected';
        return;
      }
      const formData = new FormData();
      formData.append('image', this.newImageFile);
      if (this.event.imageUrl && this.event.imageUrl.startsWith(process.env.VUE_APP_SERVER_ROUTE + 'uploads/')) {
        try {
          await axios.delete(`events/delete-event-image`, {
            data: { imagePath: this.event.imageUrl.replace(process.env.VUE_APP_SERVER_ROUTE, '') },
            withCredentials: true
          });
        } catch (error) {
          console.error("Error deleting old image:", error);
        }
      }
      try {
        const response = await axios.post(`events/upload-event-image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        });
        this.event.imageUrl = `${process.env.VUE_APP_SERVER_ROUTE}${response.data.url}`;
      } catch (error) {
        this.errorMessage = `Image Upload Error: ${error.message}`;
      }
    },
    addTag() {
      if (this.newTag && !this.event.tags.includes(this.newTag)) {
        this.event.tags.push(this.newTag);
        this.newTag = ""; // Reset the input
      }
    },
    removeTag(tagToRemove) {
      this.event.tags = this.event.tags.filter(tag => tag !== tagToRemove);
    },
    displayDate(unixTimestamp) {
      return this.formatDate(unixTimestamp);
    },
    toggleOccurrences() {
      this.showOccurrences = !this.showOccurrences;
    },
    toggleAddOccurrenceForm() {
      this.showAddOccurrence = true;
    },
    addOccurrence() {
      if (!this.newOccurrenceStart || !this.newOccurrenceEnd) {
        this.occurrencesErrorMessage = 'Merci de renseigner les champs de début et de fin.'
        return
      }
      const newOccurrence = [
        this.convertToUnixTimestamp(this.newOccurrenceStart),
        this.convertToUnixTimestamp(this.newOccurrenceEnd)
      ];
      const doesOccurrenceExist = this.event.occurrences.some(occ =>
        occ[0] === newOccurrence[0] && occ[1] === newOccurrence[1]
      );
      if (doesOccurrenceExist) {
        this.occurrencesErrorMessage = 'Cet horaire existe déjà.';
        return;
      }
      console.log(newOccurrence)
      this.event.occurrences.push(newOccurrence);
      this.event.occurrences.sort((a, b) => a[0] - b[0]);
      this.occurrencesErrorMessage = '';
      this.occurrencesChanged = true;
      this.newOccurrenceStart = '';
      this.newOccurrenceEnd = '';
      this.showAddOccurrence = false;
    },
    timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    },
    parseTime(time) {
      const [hours, minutes] = time.split(':');
      return { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
    },
    toggleAddRecurrenceForm() {
      this.showAddRecurrence = true;
    },
    generateRecurrences(eventStartDate, eventEndDate, dayOfWeek, timeSlots) {
      const recurrences = [];
      const dayIndexes = {
        'Dimanche': 0,
        'Lundi': 1,
        'Mardi': 2,
        'Mercredi': 3,
        'Jeudi': 4,
        'Vendredi': 5,
        'Samedi': 6
      };
      const targetDayIndex = dayIndexes[dayOfWeek];
      let currentDate = new Date(eventStartDate);
      while (currentDate <= eventEndDate) {
        if (currentDate.getUTCDay() === targetDayIndex) {
          for (let slot of timeSlots) {
            const startTime = new Date(currentDate);
            startTime.setUTCHours(slot.startHour, slot.startMinute, 0, 0);
            const endTime = new Date(currentDate);
            endTime.setUTCHours(slot.endHour, slot.endMinute, 0, 0);
            if (endTime > eventStartDate && startTime < eventEndDate) {
              const unixStart = this.convertToUnixTimestamp(this.convertGeneratedtoDateTimeLocal(startTime));
              const unixEnd = this.convertToUnixTimestamp(this.convertGeneratedtoDateTimeLocal(endTime))
              recurrences.push([unixStart, unixEnd]);
            }
          }
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return recurrences;
    },
    generateAndAddOccurrences() {
      if (!this.event.startDate) {
        this.recurrenceErrorMessage = "La date de début de l'évènement est requise.";
        return;
      }
      if (!this.event.endDate) {
        this.recurrenceErrorMessage = "La date de fin de l'évènement est requise.";
        return;
      }
      if (!this.newTimeStart) {
        this.recurrenceErrorMessage = "L'heure de début pour cet horaire est requise.";
        return;
      }
      if (!this.newTimeEnd) {
        this.recurrenceErrorMessage = "L'heure de fin pour cet horaire est requise.";
        return;
      }
      if (!this.selectedDays.length) {
        this.recurrenceErrorMessage = "Veuillez sélectionner au moins un jour de la semaine.";
        return;
      }
      const eventStartDate = new Date(this.event.startDate);
      const eventEndDate = new Date(this.event.endDate);
      const { hours: startHour, minutes: startMinute } = this.parseTime(this.newTimeStart);
      const { hours: endHour, minutes: endMinute } = this.parseTime(this.newTimeEnd);
      const timeSlots = [
        { startHour, startMinute, endHour, endMinute }
      ];
      const recurrences = [];
      this.selectedDays.forEach(selectedDay => {
        const dayRecurrences = this.generateRecurrences(eventStartDate, eventEndDate, selectedDay, timeSlots);
        recurrences.push(...dayRecurrences);
      });
      if (recurrences.length == 0) {
        this.recurrenceErrorMessage = "Aucun horaire entre ces dates";
      } else {
        recurrences.forEach(pair => {
          const doesOccurrenceExist = this.event.occurrences.some(occ =>
            occ[0] === pair[0] && occ[1] === pair[1]
          );
          if (!doesOccurrenceExist) {
            this.event.occurrences.push(pair);
          } else {
            this.recurrenceDuplicateMessage = "Un horaire est identique et n'a pas été ajouté";
          }
        });
        this.event.occurrences.sort((a, b) => a[0] - b[0]);
        this.occurrencesChanged = true;
        this.recurrenceErrorMessage = '';
        this.newTimeStart = '';
        this.newTimeEnd = '';
        this.selectedDays = [];
      }
    },
    deleteOccurrence(index) {
      console.log("Attempting to delete occurrence at index:", index);
      this.event.occurrences.splice(index, 1);
      this.occurrencesChanged = true;
    },
    getCoordinates() {
      return new Promise((resolve, reject) => {
        const address = `${this.event.locationStreet}, ${this.event.locationZipCode}, ${this.event.locationCity}`;
        fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
          .then(response => response.json())
          .then(data => {
            if (data.length > 0) {
              this.latitude = data[0].lat;
              this.longitude = data[0].lon;
              this.event.locationCoordinates = `[${data[0].lat},${data[0].lon}]`;
              this.coordinatesMessage = `Coordonnées trouvées: Latitude ${this.latitude}, Longitude ${this.longitude}`;
              this.coordinatesError = false;
              resolve();
            } else {
              this.coordinatesMessage = "Aucune coordonnée trouvée pour cette adresse.";
              this.coordinatesError = true;
              reject(new Error('Adresse non trouvée'));
            }
          })
          .catch(error => {
            this.coordinatesMessage = "Erreur lors de la recherche des coordonnées.";
            this.coordinatesError = true;
            reject(error);
          });
      });
    },
    updateCoordinates() {
      this.errorMessage = '';
      if (this.event.locationStreet && this.event.locationZipCode && this.event.locationCity) {
        this.getCoordinates()
          .then(() => {
            this.coordinatesMessage = 'Coordonnées trouvées : ' + this.event.locationCoordinates;
            this.coordinatesError = false;
          })
          .catch(error => {
            this.coordinatesMessage = 'Aucune coordonnée trouvée pour cette adresse.';
            this.coordinatesError = true;
            console.error('Error fetching coordinates:', error);
          });
      } else {
        this.coordinatesMessage = 'Veuillez remplir tous les champs d\'adresse.';
        this.coordinatesError = true;
      }
    },
    updatePendingStatus(event) {
      const requiredFields = [
        'title',
        'imageUrl',
        'imageCredit',
        'startDate',
        'endDate',
        'area',
        'locationName',
        'locationStreet',
        'locationZipCode',
        'locationCity',
        'locationCoordinates',
        'url'
      ];
      const allFieldsPresent = requiredFields.every(field => !!event[field]);
      return !allFieldsPresent;
    },
    async updateEvent() {
      let fieldsChangedThisTime = [];
      let eventInVue = { ...this.event };
      for (let prop in eventInVue) {
        if (prop !== 'editedFields' && prop !== 'id' && prop !== 'tracker') {
          const originalValue = this.originalEvent[prop];
          const currentValue = eventInVue[prop];
          if (currentValue !== originalValue) {
            if (prop === 'title' && /[~<>;{}[\]+=]/.test(currentValue)) {
              const forbiddenChar = currentValue.match(/[~<>;{}[\]+=]/)[0];
              this.updateMessage = `Le caractère "${forbiddenChar}" est interdit dans le titre.`;
              return;
            }
            const editedFieldIndex = eventInVue.editedFields.findIndex(edit => edit.field === prop);
            const isDateTimeField = ['startDate', 'endDate'].includes(prop);
            const originalFieldValue = editedFieldIndex !== -1 ? eventInVue.editedFields[editedFieldIndex].originalValue : (isDateTimeField ? this.convertToUnixTimestamp(originalValue) : originalValue);
            const currentFieldValue = isDateTimeField ? this.convertToUnixTimestamp(currentValue) : currentValue;
            let editedField = {
              field: prop,
              originalValue: originalFieldValue,
              latestValue: currentFieldValue,
              editorName: this.user.name
            };
            if (prop === 'occurrences' && !this.checkIfOccurrencesNeedTracking()) {
              continue;
            }
            if (editedFieldIndex !== -1) {
              eventInVue.editedFields[editedFieldIndex] = editedField;
            } else {
              eventInVue.editedFields.push(editedField);
            }
            fieldsChangedThisTime.push(editedField);
          }
        }
      }
      console.log(fieldsChangedThisTime)
      const updatedEvent = {
        ...eventInVue,
        startDate: this.convertToUnixTimestamp(eventInVue.startDate),
        endDate: this.convertToUnixTimestamp(eventInVue.endDate),
        occurrences: this.event.occurrences,
        isPending: this.updatePendingStatus(eventInVue)
      };
      axios.put(`events/${eventInVue.id}`, updatedEvent, { withCredentials: true })
        .then(() => {
          if (fieldsChangedThisTime.length === 0 || fieldsChangedThisTime == []) {
            this.updateMessage = 'Informations identiques';
            return;
          } else {
            this.updateMessage = 'Event successfully updated.';
            this.errorMessage = '';
            this.coordinatesError = false;
            this.recurrenceDuplicateMessage = '';
            this.occurrencesErrorMessage = '';
            this.recurrenceErrorMessage = '';
            this.coordinatesMessage = '';
            this.occurrencesChanged = false;
          }
        })
        .catch(error => {
          this.errorMessage = 'An error occurred while trying to update the event.';
          console.error('Error:', error);
        });
    },
    revertChange(fieldName) {
      const editedFieldIndex = this.event.editedFields.findIndex(edit => edit.field === fieldName);
      if (editedFieldIndex === -1) {
        console.error(`Could not find edited field for: ${fieldName}`);
        return;
      }
      const originalValue = this.event.editedFields[editedFieldIndex].originalValue;
      if (fieldName === 'startDate' || fieldName === 'endDate') {
        this.event[fieldName] = this.convertUnixToDateTimeLocal(originalValue);
      } else {
        this.event[fieldName] = originalValue;
      }
      this.event.editedFields.splice(editedFieldIndex, 1);
      this.updateMessage = 'Valeur de champ restaurée.';
      this.errorMessage = '';
    },
    isFieldEdited(fieldName) {
      return this.event.editedFields.some(edit => edit.field === fieldName);
    },
    checkIfOccurrencesNeedTracking() {
      const originalOccurrences = this.originalEvent.occurrences || [];
      const currentOccurrences = this.event.occurrences || [];
      if (!originalOccurrences.length && !currentOccurrences.length) {
        return false;
      }
      if (originalOccurrences.length !== currentOccurrences.length) {
        return true;
      }
      for (let i = 0; i < originalOccurrences.length; i++) {
        if (JSON.stringify(originalOccurrences[i]) !== JSON.stringify(currentOccurrences[i])) {
          return true;
        }
      }
      return false;
    },
    async logout() {
      await this.$store.dispatch('logout');
      this.$emit('logout');
    }
  },
  watch: {
    'event.startDate': function (pickerValue) {
      if (!this.event.endDate || new Date(pickerValue) > new Date(this.event.endDate)) {
        this.event.endDate = pickerValue;
      }
      this.minEndDate = pickerValue;
    },
    'event.endDate': function (pickerValue) {
      if (!this.event.startDate || new Date(this.event.startDate) > new Date(pickerValue)) {
        this.event.startDate = pickerValue;
      }
      this.maxStartDate = pickerValue;
    },
    'newOccurrenceStart': function (pickerValue) {
      if (!this.newOccurrenceEnd || new Date(pickerValue) > new Date(this.newOccurrenceEnd)) {
        this.newOccurrenceEnd = pickerValue;
      }
      this.minOccurrenceEndDate = pickerValue;
    },
    'newOccurrenceEnd': function (pickerValue) {
      if (!this.newOccurrenceStart || new Date(this.newOccurrenceStart) > new Date(pickerValue)) {
        this.newOccurrenceStart = pickerValue;
      }
      this.maxOccurrenceStartDate = pickerValue;
    },
    'newTimeStart': function (pickerValue) {
      if (this.newTimeEnd == '' || (this.timeToMinutes(pickerValue) >= this.timeToMinutes(this.newTimeEnd))) {
        this.newTimeEnd = pickerValue;
      }
    },
    'newTimeEnd': function (pickerValue) {
      if (this.newTimeStart == '' || (this.timeToMinutes(pickerValue) <= this.timeToMinutes(this.newTimeStart))) {
        this.newTimeStart = pickerValue;
      }
    }
  }
}
</script>

<style scoped>
.event-details-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.event-details {
  text-align: left;
}

.image-wrapper {
  margin-bottom: 20px;
}

.event-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
  object-fit: cover;
}

.image-credit {
  font-size: 0.9em;
  color: #ffffff;
  background-color: rgba(45, 42, 42, 0.686);
  padding: 2px 5px;
  text-align: right;
}

.event-title {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.tags {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin: 5px;
  padding: 5px 10px;
  background-color: #ddd;
  border-radius: 5px;
}

.missing {
  color: red;
}

h1 {
  margin-top: 30px;
  margin-bottom: 50px;
}

h2 {
  margin-top: 60px;
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  text-align: left;
  display: block;   
  margin-top: 20px;
  padding-left: 3px;
}

.form-check-label {
  text-align: left;
  display: block;   
  margin-top: 20px;
  padding-left: 3px;
}

.description p {
  margin: 0;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-control::placeholder {
  color: lightgrey;
  font-style: italic;
}

.form-check-input {
  margin-left: 0;
}

.clickable-title {
  cursor: pointer;
  color: #0056b3;
}

.clickable-title:hover {
  text-decoration: underline;
}

.map-container {
  margin-top: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 10px;
}

@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
}
</style>
