<template>
  <div class="event-details-container">
    <h1 class="mb-5">Dernières mises à jour</h1>
    <div v-if="message" :class="messageClass">{{ message }}</div>

    <div v-if="tasks.length === 0">Il n'y a actuellement aucune tâche.</div>
    <div v-else class="task-list">
      <div class="task-item" v-for="task in tasks" :key="task.taskName">
        <div>{{ task.taskName }}</div>
        <div>{{ task.lastExecuted }}</div>
        <div class="actions">
          <button @click="triggerODParis" class="btn btn-secondary btn-sm">
            <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../axiosConfig.js";

export default {
  name: 'cronBoard',
  data() {
    return {
      tasks: [],
      message: '',
      isError: false
    };
  },
  computed: {
    messageClass() {
      return this.isError ? 'error' : 'success';
    }
  },
  mounted() {
    this.fetchODParis();
  },
  methods: {
    fetchODParis() {
      axios.get(`cron/last-update/opendata-paris`)
        .then(response => {
          this.tasks = [{
            taskName: 'opendata-paris',
            lastExecuted: new Date(response.data.lastExecuted).toLocaleString()
          }];
        })
        .catch(error => {
          console.error('Error fetching tasks:', error);
          this.message = 'Failed to load tasks.';
          this.isError = true;
        });
    },
    triggerODParis() {
      axios.post(`cron/trigger-update/opendata-paris`)
        .then(() => {
          this.message = 'Mise à jour manuelle pour opendata-paris.';
          this.isError = false;
        })
        .catch(error => {
          console.error('Error triggering update:', error);
          this.message = 'Failed to trigger update for opendata-paris';
          this.isError = true;
        });
    }
  }
};
</script>

<style scoped>
.event-details-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

h1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.task-list {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  align-items: center;
}

.task-item:hover {
  background-color: #f1f1f1;
}

.actions {
  display: flex;
  gap: 10px;
}

.btn-rose {
  background-color: #e83e8c;
}

.btn-rose .fa-refresh {
  color: white;
}
</style>
