<template>
  <div class="mt-5 mt-lg-5 px-2 px-lg-5">
    <h1>Tous les événements</h1>
    
    <div class="form-group">
      <label for="searchQuery">Recherche:</label>
      <input type="text" v-model="searchQuery" id="searchQuery" class="form-control">
    </div>

    <div class="form-group mt-3">
      <label for="areaFilter">Filtrer par région :</label>
      <select v-model="areaFilter" id="areaFilter" class="form-select">
        <option value="">Toute la France</option>
        <option v-for="area in areas" :key="area">{{ area }}</option>
      </select>
    </div>

    <p v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</p>

    <div> 
      <table class="table mt-4 mb-5">
        <thead>
          <tr>
            <th @click="sortBy('title')" class="clickable">Titre
              <span v-if="sortKey === 'title'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('startDate')" class="clickable">Début
              <span v-if="sortKey === 'startDate'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('endDate')" class="clickable">Fin
              <span v-if="sortKey === 'endDate'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('createdAt')" class="clickable">Ajout
              <span v-if="sortKey === 'createdAt'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('source')" class="clickable">Source
              <span v-if="sortKey === 'source'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in currentAndPastEvents.currentEvents" :key="event.id" :class="{ 'pending-event': event.isPending }">
            <td class="d-flex align-items-center">
                <img :src="event.imageUrl" loading="lazy" alt="Event image" class="event-image me-3">
                <router-link :to="{ name: 'EventDetails', params: { id: event.id } }" class="event-title-link">
                  <span class="event-title">{{ event.title }}</span>
                </router-link>
            </td>
            <td>{{ this.formatDate(event.startDate) }}</td>
            <td>{{ this.formatDate(event.endDate) }}</td>
            <td>{{ this.formatCreatedAt(event.createdAt) }}</td>
            <td>{{ getSource(event) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="clickable-title mt-4 mb-5" @click="togglePastEvents">
      Évènements passés
      <i class="fas" :class="showPastEvents ? 'fa-eye' : 'fa-eye-slash'"></i>
    </h2>

    <table v-if="showPastEvents" class="table table-striped mt-3 mb-5">
      <thead>
        <tr>
          <th @click="sortBy('title')" class="clickable">Titre
            <span v-if="sortKey === 'title'">
              <span v-if="sortDesc">&#9650;</span>
              <span v-else>&#9660;</span>
            </span>
          </th>
          <th @click="sortBy('startDate')" class="clickable">Début
            <span v-if="sortKey === 'startDate'">
              <span v-if="sortDesc">&#9650;</span>
              <span v-else>&#9660;</span>
            </span>
          </th>
          <th @click="sortBy('endDate')" class="clickable">Fin
            <span v-if="sortKey === 'endDate'">
              <span v-if="sortDesc">&#9650;</span>
              <span v-else>&#9660;</span>
            </span>
          </th>
          <th @click="sortBy('createdAt')" class="clickable">Ajout
            <span v-if="sortKey === 'createdAt'">
              <span v-if="sortDesc">&#9650;</span>
              <span v-else>&#9660;</span>
            </span>
          </th>
          <th @click="sortBy('source')" class="clickable">Source
            <span v-if="sortKey === 'source'">
              <span v-if="sortDesc">&#9650;</span>
              <span v-else>&#9660;</span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in currentAndPastEvents.pastEvents" :key="event.id" :class="{ 'pending-event': event.isPending }">
          <td class="d-flex align-items-center">
            <img :src="event.imageUrl" loading="lazy" alt="Event image" class="event-image me-3">
            <router-link :to="{ name: 'EventDetails', params: { id: event.id } }" class="event-title-link">
              <span class="event-title">{{ event.title }}</span>
            </router-link>
          </td>
          <td>{{ this.formatDate(event.startDate) }}</td>
          <td>{{ this.formatDate(event.endDate) }}</td>
          <td>{{ this.formatCreatedAt(event.createdAt) }}</td>
          <td>{{ getSource(event) }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <button v-if="showBackToTop" @click="scrollToTop" class="btn btn-secondary back-to-top">
    <i class="fas fa-arrow-up"></i>
  </button>
</template>

<script>
import axios from "../../axiosConfig.js";
import dateMixin from "../../mixins/dateMixin.js";
import '@fortawesome/fontawesome-free/css/all.css';
import { mapState } from 'vuex';

export default {
  mixins: [dateMixin],
  data() {
    return {
      events: [],
      searchQuery: '',
      sortKey: '',
      sortDesc: false,
      sortOrders: {
        title: 1,
        startDate: 1,
        endDate: 1,
        createdAt: 1,
        source: 1
      },
      showPastEvents: false,
      errorMessage: '',
      areaFilter: '',
      showBackToTop: false 
    };
  },
  computed: {
    ...mapState(['user', 'userRole']),
    areas() {
      const uniqueAreas = new Set(this.events.map(event => event.area));
      return Array.from(uniqueAreas);
    },
    filteredEvents() {
      return this.events
        .filter(event =>
          event.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .filter(event =>
          this.areaFilter ? event.area === this.areaFilter : true
        );
    },
    currentAndPastEvents() {
      const now = Math.floor(Date.now() / 1000);
      const order = this.sortOrders[this.sortKey] || 1;
      const key = this.sortKey;

      const sortFunction = function (a, b) {
        if (a.isPending && !b.isPending) return -1;
        if (b.isPending && !a.isPending) return 1;

        let valueA = key === 'updatedAt' ? this.updatedAt(a) : (key ? a[key] : a);
        let valueB = key === 'updatedAt' ? this.updatedAt(b) : (key ? b[key] : b);
        return (valueA === valueB ? 0 : valueA < valueB ? -1 : 1) * order;
      }.bind(this);

      const sortedEvents = [...this.filteredEvents].sort(sortFunction);
      const currentEvents = sortedEvents.filter(event => !event.endDate || event.endDate >= now);
      const pastEvents = sortedEvents.filter(event => event.endDate && event.endDate < now);

      return {
        currentEvents: currentEvents,
        pastEvents: pastEvents
      };
    }
  },
  mounted() {
    this.fetchEvents();
    window.addEventListener('scroll', this.handleScroll); 
  },
  beforeUnmount() { 
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    fetchEvents() {
      axios.get(`events`, {
        withCredentials: true
      })
        .then(response => {
          this.events = response.data;
        })
        .catch(error => {
          this.errorMessage = `Erreur: ${error.message}`;
        });
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    getSource(event) {
      return event.tracker ? 'OpenData' : 'Int';
    },
    editEvent(event) {
      this.$router.push({ name: 'EventEdit', params: { id: event.id } });
    },
    togglePastEvents() {
      this.showPastEvents = !this.showPastEvents;
    },
    async logout() {
      await this.$store.dispatch('logout');
      this.$emit('logout');
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 100;
    }
  }
};
</script>

<style scoped>
.user-name {
  color: black;
  font-size: 1.2rem;
  padding-left: 15px; 
}

/* Mobile view adjustments */
@media (max-width: 991px) {
  .d-flex .user-name {
    text-align: left;
  }

  .navbar-toggler {
    margin-right: 15px; 
  }
}

/* Desktop view adjustments */
@media (min-width: 992px) {
  .user-name {
    text-align: left; 
    padding-left: 2px; 
  }
}

table img {
  width: 150px;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}

.pending-event span, .pending-event td {
  color: rgb(203, 56, 56);
}

.clickable-title,
th.clickable {
  cursor: pointer;
}

td, th {
  vertical-align: middle;
}

.d-flex.align-items-center {
  height: 100%;
}

.event-image {
  width: 150px;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}

.event-title-link {
  text-decoration: none;
}

.event-title {
  color: #333;
  font-size: 1rem;
  text-align: left;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>
