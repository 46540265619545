<template>
  <div class="editor-form-container">
    <h1>Créer un profil Éditeur</h1>
    <form @submit.prevent="createEditor">
      <div class="form-group">
        <label class="form-label">Nom</label>
        <input v-model="name" type="text" class="form-control" required placeholder="Entrez le nom" />
      </div>
      <div class="form-group">
        <label class="form-label">Email</label>
        <input v-model="email" type="email" class="form-control" required placeholder="Entrez l'email" />
      </div>


    <div class="btn-group">
      <router-link to="/profils" class="btn btn-secondary mt-4">Annuler</router-link>

      <div :class="messageClass" class="message">{{ message }}</div>
      <button type="submit" class="btn btn-primary mt-4">Envoyer pour confirmation</button>
    </div>
    </form>
  </div>
</template>

<script>
import axios from "../../axiosConfig.js";

export default {
  data() {
    return {
      name: '',
      email: '',
      message: '',
      isSuccess: false
    };
  },
  computed: {
    messageClass() {
      return this.isSuccess ? 'success' : 'error';
    }
  },
  methods: {
    createEditor() {
      const newEditor = {
        name: this.name,
        email: this.email
      };

      axios
        .post(`auth/editors`, newEditor, { withCredentials: true })
        .then(() => {
          this.message = "Profil éditeur créé : un email a été envoyé à l'adresse indiquée.";
          this.isSuccess = true;
        })
        .catch((error) => {
          this.message = `Erreur: ${error.message}`;
          this.isSuccess = false;
        });
    },
    async logout() {
      await this.$store.dispatch('logout');
      this.$emit('logout');
    }
  }
};
</script>

<style scoped>
.success {
  color: green;
}

.error {
  color: red;
}

.editor-form-container {
  margin: 0 auto;
  max-width: 600px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  text-align: left;
  display: block;   
  margin-top: 20px;
  padding-left: 3px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mt-4 {
  margin-top: 20px;
}
</style>
