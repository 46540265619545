<template>
  <div class="password-reset-container">
    <h1>Définir ou Réinitialiser le Mot de Passe</h1>
    <form @submit.prevent="setPassword" class="form-container">
      <div class="form-group">
        <label for="password">Nouveau Mot de Passe:</label>
        <input v-model="password" type="password" class="form-control" required placeholder="Entrez le nouveau mot de passe" />
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirmer le Mot de Passe:</label>
        <input v-model="confirmPassword" type="password" class="form-control" required placeholder="Confirmez le mot de passe" />
      </div>
      <button type="submit" class="btn btn-primary mt-4">Soumettre</button>
    </form>
    <div :class="messageClass" class="message">{{ message }}</div>
  </div>
</template>
  
<script>
import axios from "../../axiosConfig.js";

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      message: '',
      isSuccess: false
    };
  },
  computed: {
    messageClass() {
      return this.isSuccess ? 'success' : 'error';
    }
  },
  methods: {
    setPassword() {
      if (this.password !== this.confirmPassword) {
        this.message = "Les mots de passe ne correspondent pas.";
        this.isSuccess = false;
        return;
      }

      axios
        .post(`auth/mot-de-passe`, {
          token: this.$route.query.token,
          password: this.password
        })
        .then(() => {
          this.message = "Mot de passe défini avec succès!";
          this.isSuccess = true;
        })
        .catch(error => {
          this.message = `Erreur: ${error.message}`;
          this.isSuccess = false;
        });
    }
  }
};
</script>
  
<style scoped>
.password-reset-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 600px;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  text-align: left;
  display: block;   
  margin-top: 20px;
  padding-left: 3px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
