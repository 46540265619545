<template>
  <div id="app" class="container-fluid w-100 mx-0 px-0">
    <!-- Navbar for mobile view -->
    <div v-if="isLoggedIn">
      <nav class="navbar navbar-expand-lg navbar-light nav-block d-lg-none w-100 mx-0 px-0 sticky-top">
        <div class="d-flex w-100 align-items-center justify-content-between mx-0 px-2">
          <div class="user-name" v-if="this.$store.state.user">Bonjour, {{ this.$store.state.user.name }}</div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <div class="collapse navbar-collapse w-100" id="navbarMenu">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100">
            <li class="nav-item">
              <router-link to="/evenements" class="nav-link" :class="{ active: isActiveRoute('/evenements') }">Tous les évènements</router-link>
            </li>
            <li class="nav-item" v-if="this.$store.state.userRole === 'admin'">
              <router-link to="/profils" class="nav-link" :class="{ active: isActiveRoute('/profils') }">Gérer les Profils</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/nouveau" class="nav-link" :class="{ active: isActiveRoute('/nouveau') }">Ajouter un événement</router-link>
            </li>
            <li class="nav-item" v-if="this.$store.state.userRole === 'admin'">
              <router-link to="/evenements-retires" class="nav-link" :class="{ active: isActiveRoute('/evenements-retires') }">Évènements retirés</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/status-maj" class="nav-link" :class="{ active: isActiveRoute('/status-maj') }">Dernières mises à jour</router-link>
            </li>
            <li class="nav-item" id="navBarLogout">
              <button @click="logout" class="btn btn-danger w-100">Déconnexion</button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    
    <!-- Main layout -->
    <div v-if="isLoggedIn" class="d-flex flex-column flex-lg-row">
      <!-- Sidebar for desktop view -->
      <div class="d-none d-lg-flex flex-column vh-100 nav-block p-3 sidebar">
        <div class="user-name mb-2" v-if="this.$store.state.user">Bonjour, {{ this.$store.state.user.name }}</div>
        <div class="list-group flex-grow-1 mx-0">
          <router-link to="/evenements" class="list-group-item list-group-item-action" :class="{ 'active': isActiveRoute('/evenements') }">Tous les évènements</router-link>
          <router-link to="/profils" class="list-group-item list-group-item-action" v-if="this.$store.state.userRole === 'admin'" :class="{ 'active': isActiveRoute('/profils') }">Gérer les Profils</router-link>
          <router-link to="/nouveau" class="list-group-item list-group-item-action" :class="{ 'active': isActiveRoute('/nouveau') }">Ajouter un événement</router-link>
          <router-link to="/evenements-retires" class="list-group-item list-group-item-action" v-if="this.$store.state.userRole === 'admin'" :class="{ 'active': isActiveRoute('/evenements-retires') }">Évènements retirés</router-link>
          <router-link to="/status-maj" class="list-group-item list-group-item-action" :class="{ 'active': isActiveRoute('/status-maj') }">Dernières mises à jour</router-link>
        </div>
        <button @click="logout" class="btn btn-danger mt-2">Déconnexion</button>
      </div>

      <!-- Main content area -->
      <div class="main-content flex-grow-1 px-2 px-lg-4">
        <router-view></router-view>
      </div>
      
    </div>
    
    <div v-if="!isLoggedIn">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState(['user', 'userRole']),
    isLoggedIn() {
      return this.user !== null;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    isActiveRoute(route) {
      return this.$route.path === route;
    }
  }
};
</script>

<style scoped>
/* import './buttons.css' */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav-block {
  background-color: rgba(235, 242, 233, 0.6);
}

.user-name {
  color: black;
  font-size: 1.2rem;
  padding-left: 15px; 
}

.nav-link.active,
.list-group-item.active {
  background-color: #b5d585;
  color: #383f46;
  border: none;
}

/* Mobile view adjustments */
@media (max-width: 991px) {
  .d-flex .user-name {
    text-align: left;
  }

  .navbar-toggler {
    margin-right: 15px; 
  }

  .navbar-collapse {
    width: 100%;
  }

  .sidebar {
    width: 100%;
    position: static;
  }

  .navbar-nav{
    margin-top: 10px;
  }

  #navBarLogout {
    margin-left: 18px;
    margin-right: 18px;
  }
}

/* Desktop view adjustments */
@media (min-width: 992px) {
  .user-name {
    text-align: left; 
    padding-left: 2px; 
  }

  .sidebar {
    position: fixed;
    width: 20%;
  }

  .flex-lg-row .flex-grow-1 {
    margin-left: 20%;
  }
}

.sidebar {
  top: 0;
  left: 0;
  bottom: 0;
}

.table-responsive {
  overflow-x: auto;
}

td, th {
  vertical-align: middle;
}

.d-flex.align-items-center {
  height: 100%;
}

.event-title-link {
  text-decoration: none;
}

.event-title {
  color: #333;
  font-size: 1rem;
  text-align: left;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>
