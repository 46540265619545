<template>
    <div class="event-details-container">
      <div v-if="event" class="event-details">
        <div class="image-wrapper">
          <img :src="event.imageUrl" alt="Event Image" class="event-image" />
          <p v-if="event.imageUrl && event.imageCredit" class="image-credit">Crédit image: {{ event.imageCredit }}</p>
        </div>
  
        <h1 class="event-title">{{ event.title }}</h1>
        <div class="tags">
          <span v-for="tag in event.tags" :key="tag" class="tag">{{ tag }}</span>
        </div>
  
        <div v-if="event.isDeleted" class="info">Cet évènement a été retiré et n'apparait pas publiquement.</div>

        <div class="dates">
          <p :class="highlightIfMissing(event.startDate)">{{ displayDate(event.startDate) }}</p>
          <p :class="highlightIfMissing(event.endDate)">{{ displayDate(event.endDate) }}</p>
        </div>

        <h2 class="mt-4">
          Horaires
        </h2>
        <div v-if="formattedOccurrences.length > 0">
          <vue-cal
            class="vuecal--green-theme"
            :events="formattedOccurrences"
            default-view="month"
            style="height: 400px"
          ></vue-cal>
        </div>
        <p v-else>Il n'y a actuellement aucune horaire pour cet événement, veuillez ajouter des horaires manuellement.</p>
  
        <h2>Description
          <span class="language-toggle">
            <span
              :class="{
                'active-language': descriptionLanguage === 'fr',
                'inactive-language': descriptionLanguage !== 'fr',
                'unavailable-language': descriptionLanguage !== 'fr' && !isEnglishDescriptionAvailable
              }"
              @click="toggleLanguage('fr')"
            >FR 🇫🇷</span> | 
            <span
              :class="{
                'active-language': descriptionLanguage === 'en',
                'inactive-language': descriptionLanguage !== 'en' && isEnglishDescriptionAvailable,
                'unavailable-language': !isEnglishDescriptionAvailable
              }"
              @click="isEnglishDescriptionAvailable && toggleLanguage('en')"
            >EN 🇺🇸</span>
          </span>
        </h2>
        <div class="description">
          <div v-if="!showFullDescription && (descriptionLanguage === 'fr' ? event.description : event.description_en)" v-html="truncatedDescriptionMarkdown"></div>
          <div v-else-if="descriptionLanguage === 'fr' ? event.description : event.description_en" v-html="fullDescriptionMarkdown"></div>
          <p v-else><i>Pas de description</i></p>
          <button v-if="!showFullDescription && (descriptionLanguage === 'fr' ? event.description : event.description_en)" @click="showFullDescription = true" class="btn btn-primary mt-2">Voir plus</button>
        </div>
  
        <h2>Lieu</h2>
          <div class="centered-content">
            <p :class="highlightIfMissing(event.locationName)">{{ displayValue(event.locationName) }}</p>
            <p :class="highlightIfMissing(event.locationStreet)">{{ displayValue(event.locationStreet) }}</p>
            <p :class="highlightIfMissing(event.locationZipCode)">{{ displayValue(event.locationZipCode) }}</p>
            <p :class="highlightIfMissing(event.locationCity)">{{ displayValue(event.locationCity) }}</p>
            <p :class="highlightIfMissing(event.locationCoordinates)">{{ displayValue(event.locationCoordinates) }}</p>
            <p :class="highlightIfMissing(event.area)">{{ displayValue(event.area) }}</p>
            <div v-if="event.locationCoordinates" class="map-container">
              <iframe
                :src="getMapUrl(event.locationCoordinates)"
                width="100%"
                height="300"
                style="border:0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
        </div>
  
        <h2>Liens</h2>
        <p v-if="event.officialUrl">
          Site officiel : <a :href="event.officialUrl" target="_blank">{{ event.officialUrl }}</a>
        </p>
        <p v-else><i>Pas de site officiel</i></p>
        <p v-if="event.url">Site de l'API: <a :href="event.url" target="_blank">{{ event.url }}</a></p>
  
        <h2>Informations supplémentaires</h2>
        <p v-if="event.audience">Public : {{ event.audience }}</p>
        <p v-if="event.access">Réservation : {{ event.access }}</p>
        <p v-if="event.price">Prix : {{ event.price }}</p>
        <p v-if="event.transport">Transports : {{ event.transport }}</p>
        <p v-if="event.rampAccess === '1'">
          <i class="fa fa-wheelchair"></i>
          Accès Rampe
        </p>
        <p v-if="event.blindAccess === '1'">
          <i class="fa fa-blind"></i>
          Accès malvoyants
        </p>
        <p v-if="event.deafAccess === '1'">
          <i class="fa fa-deaf"></i>
          Accès malentendants
        </p>
  
        <p>Source: {{ source }}</p>
        <p>Vues sur Journée: {{ event.viewCount }}</p>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  
        <div class="action-buttons">
            <div v-if="event.isDeleted" class="btn-group">
            <button @click="restoreEvent(event.id)" class="btn btn-secondary mt-2">Restaurer</button>
            </div>
            <div v-else>
            <div class="btn-group">
                <button @click="editEvent(event)" class="btn btn-primary mt-2">Modifier</button>
                <button v-if="this.$store.state.userRole === 'admin'" @click="removeEvent(event.id)" class="btn btn-danger mt-2">Retirer</button>
                <button v-if="this.$store.state.userRole === 'editor' && !showRequestRemovalForm" @click="showRequestRemovalForm = true" class="btn btn-warning mt-2">Demander le retrait</button>
            </div>
    
            <div v-if="event.removalRequested" class="success">Demande de retrait envoyée.</div>
            <div v-if="showRequestRemovalForm">
                <textarea v-model="removalReason" placeholder="Raison pour le retrait" class="form-control mt-2"></textarea>
                <button @click="requestRemoval(event.id)" class="btn btn-warning mt-2">Envoyer la demande</button>
                <button @click="cancelRequest" class="btn btn-secondary mt-2">Annuler</button>
            </div>
            </div>
        </div>
  
        <p v-if="eventRemovedMessage" class="success">{{ eventRemovedMessage }}</p>
      </div>
      <div v-else>Aucun évènement trouvé</div>
    </div>
  </template>
  
  <script>
  import axios from "../../axiosConfig.js";
  import dateMixin from "../../mixins/dateMixin.js";
  import VueCal from 'vue-cal'
  import 'vue-cal/dist/vuecal.css'
  import MarkdownIt from 'markdown-it';
  const md = new MarkdownIt();
  md.renderer.rules.image = (tokens, idx) => {
    const token = tokens[idx];
    const src = token.attrGet('src');
    const alt = token.content;
    return `<img src="${src}" alt="${alt}" style="max-width: 100%; height: auto;" />`;
  };

  export default {
    mixins: [dateMixin],
    components: {
      VueCal
    },
    data() {
      return {
        event: null,
        descriptionLanguage: 'fr',
        showFullDescription: true,
        source: '',
        showOccurrences: false,
        showRequestRemovalForm: false,
        removalReason: '',
        errorMessage: '',
        eventRemovedMessage: false,
        formattedOccurrences: []
      };
    },
    async created() {
      const id = this.$route.params.id;
      const response = await axios.get(`events/${id}`, { withCredentials: true });
      this.event = response.data;
      this.source = this.getSource(this.event.tracker);
  
      this.formattedOccurrences = this.event.occurrences.map(occurrence => ({
        start: new Date(occurrence[0] * 1000),
        end: new Date(occurrence[1] * 1000),
        title: this.event.title
      }));
    },
    methods: {
      getSource(tracker) {
        return tracker ? 'OpenData Paris' : 'Interne';
      },
      displayDate(unixTimestamp) {
        return this.formatDate(unixTimestamp);
      },
      editEvent(event) {
        this.$router.push({ name: 'EventEdit', params: { id: event.id } });
      },
      async requestRemoval(id) {
        if (!this.removalReason.trim()) {
          this.errorMessage = "Veuillez fournir une raison pour le retrait.";
          return;
        }
        try {
          const payload = {
            editorId: this.$store.state.user.id,
            reason: this.removalReason
          };
          await axios.post(`events/${id}/request-removal`, payload, { withCredentials: true });
          this.event.removalRequested = true;
          this.showRequestRemovalForm = false;
          this.removalReason = '';
          this.errorMessage = '';
        } catch (error) {
          this.errorMessage = `Erreur lors de la demande de retrait: ${error.message}`;
        }
      },
      cancelRequest() {
        this.showRequestRemovalForm = false;
        this.removalReason = '';
      },
      removeEvent(id) {
        axios.put(`events/${id}/remove`, { withCredentials: true })
          .then(() => {
            this.eventRemovedMessage = "L'événement a été retiré avec succès.";
            this.event.isDeleted = true;
          })
          .catch(error => {
            this.errorMessage = `Erreur: ${error.message}`;
          });
      },
      restoreEvent(id) {
        axios.put(`events/${id}/restore`, { withCredentials: true })
          .then(() => {
            this.eventRemovedMessage = "L'événement a été restauré avec succès.";
            this.event.isDeleted = false;
          })
          .catch(error => {
            this.errorMessage = `Erreur: ${error.message}`;
          });
      },
      highlightIfMissing(fieldValue) {
        return fieldValue ? '' : 'missing';
      },
      displayValue(fieldValue) {
        return fieldValue ? fieldValue : 'Champ Vide';
      },
      getMapUrl(coordinates) {
        const [lat, lng] = coordinates.slice(1, -1).split(',').map(coord => coord.trim());
        return `https://www.openstreetmap.org/export/embed.html?bbox=${lng},${lat},${lng},${lat}&layer=mapnik&marker=${lat},${lng}`;
      },
        toggleLanguage(lang) {
        this.descriptionLanguage = lang;
      }
    },
    computed: {
      truncatedDescriptionMarkdown() {
        const description = this.descriptionLanguage === 'fr' ? this.truncatedDescription : this.truncatedDescriptionEn;
        return description ? md.render(description) : '';
      },
      fullDescriptionMarkdown() {
        const description = this.descriptionLanguage === 'fr' ? this.event.description : this.event.description_en;
        return description ? md.render(description) : '';
      },
      truncatedDescriptionEn() {
        if (!this.event.description_en) return '';
        const maxLength = 100; // adjust the length as needed
        return this.event.description_en.length > maxLength
          ? this.event.description_en.substring(0, maxLength) + '...'
          : this.event.description_en;
      },
      isEnglishDescriptionAvailable() {
        return !!this.event.description_en;
      }
    }
  };
  </script>
  
  <style scoped>
  .event-details-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  .event-details {
    text-align: left;
  }
  
  .image-wrapper {
    margin-bottom: 20px;
  }

  .centered-content {
    text-align: center;
  }
  
  .event-image {
    width: 100%;
    height: auto;
    max-height: 500px;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .image-credit {
    font-size: 0.9em;
    color: #ffffff;
    background-color: rgba(45, 42, 42, 0.686);
    padding: 2px 5px;
    text-align: right;
  }
  
  .event-title {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .tags {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
  }

  .tag {
    margin: 5px;
    padding: 5px 10px;
    background-color: #acd393;
    border-radius: 5px;
  }

  .dates {
    text-align: center; 
  }
  
  .info, .success, .error {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .info {
    color: #0056b3;
    background-color: #e6f2ff;
  }
  
  .success {
    color: #155724;
    background-color: #d4edda;
  }
  
  .error {
    color: #721c24;
    background-color: #f8d7da;
  }
  
  .missing {
    color: red;
  }
  
  .action-buttons {
    margin-top: 50px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .clickable-title {
    cursor: pointer;
    color: #0056b3;
  }
  
  .clickable-title:hover {
    text-decoration: underline;
  }
  
  .description p {
    margin: 0;
  }
  
  .map-container {
    margin-top: 20px;
  }

  .language-toggle {
  font-size: 0.89em;
  margin-left: 10px;
}

.language-toggle span {
  margin: 0 5px;
}

.active-language {
  color: rgb(98, 149, 104);
  opacity: 0.95;
}

.inactive-language {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.unavailable-language {
  color: lightgrey;
  text-decoration: line-through;
  cursor: default;
}
  </style>
  