<template>
  <div class="login-container d-flex flex-column justify-content-center align-items-center vh-100">
    <div class="login-form w-75 px-4 py-5">
      <h1 class="display-4 text-center mb-5">Journée</h1>
      <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
      <div class="mb-3">
        <label for="email" class="form-label">E-mail</label>
        <input type="text" id="email" v-model="email" class="form-control" placeholder="Entrez votre adresse e-mail" />
      </div>
      <div class="mb-4">
        <label for="password" class="form-label">Mot de passe</label>
        <input type="password" id="password" v-model="password" class="form-control" placeholder="Entrez votre mot de passe" />
      </div>
      <button @click="login" class="btn btn-primary w-100">Se connecter</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('login', { email: this.email, password: this.password });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = 'Échec de la connexion. Veuillez vérifier vos identifiants et réessayer.';
        }
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  background-color: #f8f9fa;
  width: 100%;
}

.login-form {
  max-width: 600px;
  width: 100%;
}

.form-label {
  font-weight: bold;
  text-align: left;
  display: block;
}

.form-control {
  border: 1px solid #ced4da;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control:focus {
  border-color: #557328;
  box-shadow: 0 0 0 0.2rem rgba(120, 255, 174, 0.247);
}

h1 {
  margin-bottom: 2.5rem;
}
</style>
