import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store/index';
import Login from '@/components/loginComp/LoginComp.vue';
import EventList from '@/components/eventsComp/EventList.vue';
import EventRemovedList from '@/components/eventsComp/EventRemovedList.vue';
import EventCreate from '@/components/eventsComp/EventCreate.vue';
import EventDetails from '@/components/eventsComp/EventDetails.vue';
import EventEdit from '@/components/eventsComp/EventEdit.vue';
import EditorList from '@/components/editorsComp/EditorList.vue';
import EditorCreate from '@/components/editorsComp/EditorCreate.vue';
import EditorEdit from '@/components/editorsComp/EditorEdit.vue';
import PasswordSetting from '@/components/editorsComp/PasswordSetting.vue';
import StatusMaj from '@/components/cronComp/CronBoard.vue';

const routes = [
  {
    path: '/',
    name: 'Évènements',
    component: EventList,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: "S'identifier",
    component: Login
  },
  {
    path: '/profils',
    name: 'Gérer les profils éditeurs',
    component: EditorList,
    meta: { requiresAuth: true }
  },
  {
    path: '/profils/nouveau',
    name: 'Nouveau profil éditeur',
    component: EditorCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/profils/:id/modifier',
    name: 'Modifier un profil éditeur',
    component: EditorEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/mot-de-passe',
    name: 'Définir le Mot de Passe',
    component: PasswordSetting
  },
  {
    path: '/evenements',
    name: 'EventList',
    component: EventList,
    meta: { requiresAuth: true }
  },
  {
    path: '/evenements-retires',
    name: 'EventRemovedList',
    component: EventRemovedList,
    meta: { requiresAuth: true }
  },
  {
    path: '/nouveau',
    name: 'EventCreate',
    component: EventCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/evenements/:id',
    name: 'EventDetails',
    component: EventDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/evenements/:id/modifier/',
    name: 'EventEdit',
    component: EventEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/status-maj/',
    name: 'StatusMaj',
    component: StatusMaj,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {
    await store.dispatch('checkAuth');
  }

  const isAuthenticated = store.state.user !== null;
  const userRole = store.state.userRole;
  const isAdminRoute = to.path.startsWith('/profils') || to.path.startsWith('/evenements-retires');

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (isAdminRoute && userRole !== 'admin') {
    next('/login');
  } else {
    if (to.path === '/login' && isAuthenticated) {
      console.log('User is already authenticated, redirecting to home...');
      next('/');
    } else {
      next();
    }
  }
});


export default router;
