<template>
    <div class="event-details-container">
      <h1>Nouvel évènement</h1>
      <p v-if="eventCreatedMessage" class="success">{{ eventCreatedMessage }}</p>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  
      <div class="image-wrapper">
        <img v-if="newEvent.imageUrl" :src="newEvent.imageUrl" alt="Event Image" class="event-image">
        <input type="file" @change="uploadImage($event)" class="form-control">
      </div>
      <p v-if="imageError" class="error">L'image est requise</p>
  
      <div class="form-group">
        <label class="form-label">Crédit image</label>
        <input type="text" v-model="newEvent.imageCredit" class="form-control" placeholder="Ajouter le crédit image">
        <p v-if="imageCreditError" class="error">Le crédit image est requis</p>
      </div>
  
      <div class="form-group">
        <label class="form-label">Titre</label>
        <input type="text" v-model="newEvent.title" class="form-control" placeholder="Ajouter le titre">
        <p v-if="titleError" class="error">Le titre est requis</p>
      </div>
  
      <div class="form-group">
        <label class="form-label">Tags</label>
        <div class="tags">
          <span v-for="tag in newEvent.tags" :key="tag" class="tag">
            {{ tag }}
            <button @click="removeTag(tag)">x</button>
          </span>
        </div>
        <input type="text" v-model="newTag" @keyup.enter="addTag" class="form-control mt-2" placeholder="Ajouter un tag">
        <p v-if="tagsError" class="error">Un tag minimum est requis</p>
      </div>

      <div class="row">
        <div class="col-md-6">
            <label class="form-label">Début</label>
            <input type="datetime-local" v-model="newEvent.startDate" :max="maxStartDate" class="form-control">
            <p v-if="startDateError" class="error">La date de début est requise</p>
          </div>
  
         <div class="col-md-6">
             <label class="form-label">Fin</label>
              <input type="datetime-local" v-model="newEvent.endDate" :min="minEndDate" class="form-control">
              <p v-if="endDateError" class="error">La date de fin est requise</p>
         </div>
      </div>
  
      <h2>Horaires</h2>
      <div>
        <div v-for="(occurrence, index) in newEvent.occurrences" :key="index" class="form-group">
          <p>
            {{ displayDate(occurrence[0]) }} - {{ displayDate(occurrence[1]) }}
            <button @click="deleteOccurrence(index)" class="btn btn-danger btn-sm">Supprimer</button>
          </p>
        </div>
  
        <div class="btn-group">
          <button @click="toggleAddOccurrenceForm" class="btn btn-secondary mt-2 me-2">Ajouter un horaire unique</button>
        <button @click="toggleAddRecurrenceForm" class="btn btn-secondary mt-2">Ajouter un horaire récurrent</button>
        </div>
           <div v-if="showAddOccurrence" class="mt-2">
          <input type="datetime-local" v-model="newOccurrenceStart" :max="maxOccurrenceStartDate" class="form-control" placeholder="Début">
          <input type="datetime-local" v-model="newOccurrenceEnd" :min="minOccurrenceEndDate" class="form-control mt-2" placeholder="Fin">
          <div class="btn-group">
            <button @click="addOccurrence" class="btn btn-success mt-2">Sauvegarder</button>
          </div>
          <p v-if="occurrencesErrorMessage" class="error">{{ occurrencesErrorMessage }}</p>
        </div>
  
        <div v-if="showAddRecurrence" class="mt-2">
          <label>Jours de la semaine</label>
          <div>
            <label v-for="day in daysOfWeek" :key="day">
              <input type="checkbox" v-model="selectedDays" :value="day" class="form-check-input">{{ day }}
            </label>
          </div>
          <label>Heure de début</label>
          <input type="time" v-model="newTimeStart" :max="maxRecurrenceStartTime" class="form-control">
          <label>Heure de fin</label>
          <input type="time" v-model="newTimeEnd" :min="minRecurrenceEndTime" class="form-control">
          <div class="btn-group">
            <button @click="generateAndAddOccurrences" class="btn btn-success mt-2">Générer des horaires récurrents</button>
          </div>
          <p v-if="recurrenceErrorMessage" class="error">{{ recurrenceErrorMessage }}</p>
          <p v-if="recurrenceDuplicateMessage" class="info">{{ recurrenceDuplicateMessage }}</p>
        </div>
      </div>
      <p v-if="occurrenceError" class="error">Un horaire minimum est requis</p>
  
      <h2>Description</h2>
      <textarea v-model="newEvent.description" class="form-control" placeholder="Ajouter une description"></textarea>
      <p v-if="descriptionError" class="error">Une description est requise</p>
  
      <h2>Lieu</h2>
       
      <div class="form-group mb-3">
        <label class="form-label">Région</label>
        <select v-model="newEvent.area" class="form-select form-control">
          <option v-for="area in areas" :key="area" :value="area">{{ area }}</option>
        </select>
      </div>

      <div>
        <label class="form-label">Nom du lieu</label>
          <input type="text" v-model="newEvent.locationName" class="form-control" placeholder="Ajouter le nom du lieu">
          <p v-if="locationNameError" class="error">Le nom du lieu est requis</p>
        </div>

        <div>
          <label class="form-label">Rue</label>
          <input type="text" v-model="newEvent.locationStreet" class="form-control" placeholder="Ajouter l'adresse">
          <p v-if="locationStreetError" class="error">L'adresse est requise</p>
        </div>

      <div class="row">
        <div class="col-md-6">
          <label class="form-label">Code Postal</label>
          <input type="text" v-model="newEvent.locationZipCode" class="form-control" placeholder="Ajouter le code postal">
          <p v-if="locationZipCodeError" class="error">Le code postal est requis</p>
        </div>
        <div class="col-md-6">
          <label class="form-label">Ville</label>
          <input type="text" v-model="newEvent.locationCity" class="form-control" placeholder="Ajouter la ville">
          <p v-if="locationCityError" class="error">La ville est requise</p>
        </div>
      </div>

      <div class="btn-group">
        <button @click="initiateCoordinatesSearch" class="btn btn-secondary mt-2">Trouver les coordonnées</button>
      </div>
      <p v-if="coordinatesError" class="error">Effectuez une recherche automatique ou entrez manuellement les coordonnées ci-desous</p>
      <p v-if="coordinatesMessage" :class="coordinatesError ? 'error' : 'success'">{{ coordinatesMessage }}</p>
      <div v-if="coordinatesError">
        <input type="text" v-model="latitude" class="form-control" placeholder="Latitude">
        <input type="text" v-model="longitude" class="form-control mt-2" placeholder="Longitude">
        <div class="btn-group">
          <button @click="addManualCoordinates" class="btn btn-secondary mt-2">Ajouter les coordonnées manuellement</button>
        </div>
      </div>
  
      <h2>Informations supplémentaires</h2>

      <div class="form-group">
        <label class="form-label">Site de l'évènement</label>
        <input type="url" v-model="newEvent.officialUrl" class="form-control" placeholder="Ajouter l'URL officielle">
        <p v-if="officalUrlError" class="error">Un URL est requis</p>
      </div>

      <div class="form-group">
        <label class="form-label">Public</label>
        <input type="text" v-model="newEvent.audience" class="form-control" placeholder="Ajouter une audience">
      </div>

      <div class="form-group">
        <label class="form-label">Réservations</label>
        <input type="text" v-model="newEvent.access" class="form-control" placeholder="Ajouter les informations de réservation">
      </div>

      <div class="form-group">
        <label class="form-label">Prix</label>
        <input type="text" v-model="newEvent.price" class="form-control" placeholder="Ajouter le prix">
      </div>

      <div class="form-group">
        <label class="form-label">Transports</label>
        <input type="text" v-model="newEvent.transport" class="form-control" placeholder="Ajouter les informations de transport">
      </div>
  
     <div class="mt-5 mb-5"> 
        <div class="form-check">
        <input type="checkbox" v-model="newEvent.rampAccess" class="form-check-input" true-value="1" false-value="0">
        <label class="form-check-label">
          <i class="fa fa-wheelchair"></i> Accès Rampe
        </label>
      </div>
      <div class="form-check">
        <input type="checkbox" v-model="newEvent.blindAccess" class="form-check-input" true-value="1" false-value="0">
        <label class="form-check-label">
          <i class="fa fa-blind"></i> Accès malvoyants
        </label>
      </div>
      <div class="form-check">
        <input type="checkbox" v-model="newEvent.deafAccess" class="form-check-input" true-value="1" false-value="0">
        <label class="form-check-label">
          <i class="fa fa-deaf"></i> Accès malentendants
        </label>
      </div>
     </div>
  
     <div class="btn-group">
      <button @click="createEvent" class="btn btn-primary mt-4">Créer</button>
     </div>
    </div>
</template>
  
<script>
import axios from "../../axiosConfig.js";
  import dateMixin from "../../mixins/dateMixin.js";
  
  export default {
    mixins: [dateMixin],
    data() {
      return {
        newEvent: {
          imageUrl: '',
          imageCredit: '',
          title: '',
          tags: [],
          startDate: '',
          endDate: '',
          occurrences: [],
          description: '',
          locationName: '',
          locationStreet: '',
          locationZipCode: '',
          locationCity: '',
          locationCoordinates: '',
          area: '',
          officialUrl: '',
          audience: '',
          access: '',
          price: '',
          transport: '',
          rampAccess: '',
          blindAccess: '',
          deafAccess: '',
          url: '',
          tracker: ''
        },
        areas: [],
        imageError: false,
        imageCreditError: false,
        titleError: false,
        tagsError: false,
        startDateError: false,
        endDateError: false,
        minEndDate: '',
        maxStartDate: '',
        occurrencesErrorMessage: '',
        occurrenceError: false,
        showAddOccurrence: false,
        newOccurrenceStart: '',
        newOccurrenceEnd: '',
        recurrenceErrorMessage: '',
        recurrenceDuplicateMessage: '',
        recurrenceError: false,
        showAddRecurrence: false,
        daysOfWeek: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        selectedDays: [],
        newTimeStart: '',
        newTimeEnd: '',
        minOccurrenceEndDate: '',
        maxOccurrenceStartDate: '',
        minRecurrenceEndTime: '',
        maxRecurrenceStartTime: '',
        locationNameError: false,
        locationStreetError: false,
        locationZipCodeError: false,
        locationCityError: false,
        latitude: null,
        longitude: null,
        coordinatesMessage: '',
        coordinatesError: false,
        officalUrlError: false, 
        eventCreatedMessage: '',
        errorMessage: '',
        newTag: '',  
        descriptionError: '', 
      };
    },
    mounted() {
      this.fetchAreas();
    },
    beforeUnmount() {
      clearTimeout(this.searchTimer);
    },
    methods: {
      fetchAreas() {
        axios.get(`events`, { withCredentials: true })
          .then(response => {
            const areaSet = new Set(response.data.map(event => event.area));
            this.areas = Array.from(areaSet);
            if (this.areas.length > 0) {
              this.newEvent.area = this.areas[0];
            }
          })
          .catch(error => {
            this.errorMessage = `Erreur: ${error.message}`;
          });
      },
      async uploadImage(event) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('image', file);
  
        try {
          const response = await axios.post(`events/upload-event-image`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
          });
  
          this.newEvent.imageUrl = `${process.env.VUE_APP_SERVER_ROUTE}${response.data.url}`;
  
        } catch (error) {
          this.errorMessage = `Image Upload Error: ${error.message}`;
        }
      },
      addTag() {
        if (this.newTag && !this.newEvent.tags.includes(this.newTag)) {
          this.newEvent.tags.push(this.newTag);
          this.newTag = ""; // Reset the input
        }
      },
      removeTag(tagToRemove) {
        this.newEvent.tags = this.newEvent.tags.filter(tag => tag !== tagToRemove);
      },
      initiateCoordinatesSearch() {
        if (this.newEvent.locationStreet && this.newEvent.locationZipCode && this.newEvent.locationCity) {
          this.getCoordinates().catch(error => {
            console.error('Error fetching coordinates:', error);
          });
        } else {
          this.coordinatesMessage = "Veuillez remplir l'adresse, le code postal et la ville pour trouver les coordonnées.";
          this.coordinatesError = true;
        }
      },
      getCoordinates() {
        return new Promise((resolve, reject) => {
          const address = `${this.newEvent.locationStreet}, ${this.newEvent.locationZipCode}, ${this.newEvent.locationCity}`;
          fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
            .then(response => response.json())
            .then(data => {
              if (data.length > 0) {
                this.latitude = data[0].lat;
                this.longitude = data[0].lon;
                this.newEvent.locationCoordinates = `[${data[0].lat},${data[0].lon}]`;
                this.coordinatesMessage = `Coordonnées trouvées: Latitude ${this.latitude}, Longitude ${this.longitude}`;
                this.coordinatesError = false;
                resolve();
              } else {
                this.coordinatesMessage = "Aucune coordonnée trouvée pour cette adresse.";
                this.coordinatesError = true;
                reject(new Error('Adresse non trouvée'));
              }
            })
            .catch(error => {
              this.coordinatesMessage = "Erreur lors de la recherche des coordonnées.";
              this.coordinatesError = true;
              reject(error);
            });
        });
      },
      addManualCoordinates() {
        if (this.latitude && this.longitude) {
          this.newEvent.locationCoordinates = `[${this.latitude},${this.longitude}]`;
          this.coordinatesMessage = `Coordonnées manuelles ajoutées: Latitude ${this.latitude}, Longitude ${this.longitude}`;
          this.coordinatesError = false;
          this.latitude = '';
          this.longitude = '';
        } else {
          this.coordinatesMessage = "Veuillez entrer à la fois la latitude et la longitude.";
          this.coordinatesError = true;
        }
      },
      displayDate(unixTimestamp) {
        return this.formatDate(unixTimestamp);
      },
      timeToMinutes(time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      },
      parseTime(time) {
        const [hours, minutes] = time.split(':');
        return { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
      },
      toggleAddOccurrenceForm() {
        this.showAddOccurrence = true;
      },
      addOccurrence() {
        if (!this.newOccurrenceStart || !this.newOccurrenceEnd) {
          this.occurrencesErrorMessage = 'Merci de renseigner les champs de début et de fin.'
          return
        }
  
        const newOccurrence = [
          this.convertToUnixTimestamp(this.newOccurrenceStart),
          this.convertToUnixTimestamp(this.newOccurrenceEnd)
        ];
  
        const doesOccurrenceExist = this.newEvent.occurrences.some(occ =>
          occ[0] === newOccurrence[0] && occ[1] === newOccurrence[1]
        );
  
        if (doesOccurrenceExist) {
          this.occurrencesErrorMessage = 'Cet horaire existe déjà.';
          return;
        }
  
        this.newEvent.occurrences.push(newOccurrence);
        this.newEvent.occurrences.sort((a, b) => a[0] - b[0]);
        this.occurrencesErrorMessage = '';
        this.newOccurrenceStart = '';
        this.newOccurrenceEnd = '';
        this.showAddOccurrence = false;
      },
      toggleAddRecurrenceForm() {
        this.showAddRecurrence = true;
      },
      generateRecurrences(eventStartDate, eventEndDate, dayOfWeek, timeSlots) {
        const recurrences = [];
  
        const dayIndexes = {
          'Dimanche': 0,
          'Lundi': 1,
          'Mardi': 2,
          'Mercredi': 3,
          'Jeudi': 4,
          'Vendredi': 5,
          'Samedi': 6
        };
  
        const targetDayIndex = dayIndexes[dayOfWeek];
  
        let currentDate = new Date(eventStartDate);
        while (currentDate <= eventEndDate) {
          if (currentDate.getUTCDay() === targetDayIndex) {
            for (let slot of timeSlots) {
              const startTime = new Date(currentDate);
              startTime.setUTCHours(slot.startHour, slot.startMinute, 0, 0);
  
              const endTime = new Date(currentDate);
              endTime.setUTCHours(slot.endHour, slot.endMinute, 0, 0);
  
              if (endTime > eventStartDate && startTime < eventEndDate) {
                const unixStart = this.convertToUnixTimestamp(this.convertGeneratedtoDateTimeLocal(startTime));
                const unixEnd = this.convertToUnixTimestamp(this.convertGeneratedtoDateTimeLocal(endTime))
                recurrences.push([unixStart, unixEnd]);
              }
            }
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
  
        return recurrences;
      },
      generateAndAddOccurrences() {
        if (!this.newEvent.startDate) {
          this.recurrenceErrorMessage = "La date de début de l'évènement est requise pour générer des horaires récurrents.";
          return;
        }
  
        if (!this.newEvent.endDate) {
          this.recurrenceErrorMessage = "La date de fin de l'évènement est requise pour générer des horaires récurrents.";
          return;
        }
  
        if (!this.newTimeStart) {
          this.recurrenceErrorMessage = "L'heure de début de l'horaire récurrent est requise.";
          return;
        }
  
        if (!this.newTimeEnd) {
          this.recurrenceErrorMessage = "L'heure de fin de l'horaire récurrent est requise.";
        }
  
        if (!this.selectedDays.length) {
          this.recurrenceErrorMessage = "Veuillez sélectionner au moins un jour de la semaine.";
          return;
        }
  
        const eventStartDate = new Date(this.newEvent.startDate);
        const eventEndDate = new Date(this.newEvent.endDate);
        const { hours: startHour, minutes: startMinute } = this.parseTime(this.newTimeStart);
        const { hours: endHour, minutes: endMinute } = this.parseTime(this.newTimeEnd);
  
        const timeSlots = [
          { startHour, startMinute, endHour, endMinute }
        ];
  
        const recurrences = [];
        this.selectedDays.forEach(selectedDay => {
          const dayRecurrences = this.generateRecurrences(eventStartDate, eventEndDate, selectedDay, timeSlots);
          recurrences.push(...dayRecurrences);
        });
  
        if (recurrences.length == 0) {
          this.recurrenceErrorMessage = "Aucun horaire entre ces dates";
        } else {
          recurrences.forEach(pair => {
            const doesOccurrenceExist = this.newEvent.occurrences.some(occ =>
              occ[0] === pair[0] && occ[1] === pair[1]
            );
  
            if (!doesOccurrenceExist) {
              this.newEvent.occurrences.push(pair);
            } else {
              this.recurrenceDuplicateMessage = "Un horaire est identique et n'a pas été ajouté";
            }
          });
          this.newEvent.occurrences.sort((a, b) => a[0] - b[0]);
          this.recurrenceErrorMessage = '';
          this.newTimeStart = '';
          this.newTimeEnd = '';
          this.selectedDays = [];
        }
      },
      deleteOccurrence(index) {
        this.newEvent.occurrences.splice(index, 1);
      },
      createEvent() {
        this.imageError = !this.newEvent.imageUrl;
        this.imageCreditError = !this.newEvent.imageCredit;
        this.titleError = !this.newEvent.title;
        this.tagsError = !this.newEvent.tags?.length;
        this.startDateError = !this.newEvent.startDate;
        this.endDateError = !this.newEvent.endDate;
        this.occurrenceError = !this.newEvent.occurrences?.length;
        this.descriptionError = !this.newEvent.description;
        this.locationNameError = !this.newEvent.locationName;
        this.locationStreetError = !this.newEvent.locationStreet;
        this.locationZipCodeError = !this.newEvent.locationZipCode;
        this.locationCityError = !this.newEvent.locationCity;
        this.coordinatesError = !this.newEvent.locationCoordinates;
        this.officalUrlError = !this.newEvent.officialUrl;
        if (this.imageError 
          || this.imageCreditError 
          || this.titleError 
          || this.startDateError 
          || this.endDateError 
          || this.occurrenceError
          || this.descriptionError
          || this.locationNameError 
          || this.locationStreetError
          || this.locationZipCodeError 
          || this.locationCityError 
          || this.coordinatesError 
          || this.officalUrlError 
          || this.tagsError
        ) {
          return;
        }
  
        const formattedEvent = {
          ...this.newEvent,
          tracker: '',
          startDate: this.convertToUnixTimestamp(this.newEvent.startDate),
          endDate: this.convertToUnixTimestamp(this.newEvent.endDate),
          occurrences: this.newEvent.occurrences,
          tags: this.newEvent.tags,
          locationCoordinates: this.newEvent.locationCoordinates
        };
  
        const regex = /[~<>;{}[\]+=]/;
        if (regex.test(this.newEvent.title)) {
          const forbiddenChar = this.newEvent.title.match(regex)[0];
          this.errorMessage = `Le caractère "${forbiddenChar}" est interdit dans le titre.`;
          this.eventCreatedMessage = '';
          return;
        }
  
        axios.post(`events`, formattedEvent, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
          }
        }, { withCredentials: true })
          .then(() => {
            this.eventCreatedMessage = `Ajouté à la base de données : ${this.newEvent.title} ${this.newEvent.startDate} ${this.newEvent.endDate}`;
            this.newEvent = { title: '', startDate: '', endDate: '', area: '', tracker: '', locationName: '', locationStreet: '', locationZipCode: '', locationCity: '', locationCoordinates: '', imageUrl: '', url: '' };
            this.fetchAreas();
            this.errorMessage = '';
          })
          .catch(error => {
            this.errorMessage = `Erreur: ${error.message}`;
          });
      },
      async logout() {
        await this.$store.dispatch('logout');
        this.$emit('logout');
      }
    },
    watch: {
      'newEvent.startDate': function (pickerValue) {
        if (!this.newEvent.endDate || new Date(pickerValue) > new Date(this.newEvent.endDate)) {
          this.newEvent.endDate = pickerValue;
        }
        this.minEndDate = pickerValue;
      },
      'newEvent.endDate': function (pickerValue) {
        if (!this.newEvent.startDate || new Date(this.newEvent.startDate) > new Date(pickerValue)) {
          this.newEvent.startDate = pickerValue;
        }
        this.maxStartDate = pickerValue;
      },
      'newOccurrenceStart': function (pickerValue) {
        if (!this.newOccurrenceEnd || new Date(pickerValue) > new Date(this.newOccurrenceEnd)) {
          this.newOccurrenceEnd = pickerValue;
        }
        this.minOccurrenceEndDate = pickerValue;
      },
      'newOccurrenceEnd': function (pickerValue) {
        if (!this.newOccurrenceStart || new Date(this.newOccurrenceStart) > new Date(pickerValue)) {
          this.newOccurrenceStart = pickerValue;
        }
        this.maxOccurrenceStartDate = pickerValue;
      },
      'newTimeStart': function (pickerValue) {
        if (this.newTimeEnd == '' || (this.timeToMinutes(pickerValue) >= this.timeToMinutes(this.newTimeEnd))) {
          this.newTimeEnd = pickerValue;
        }
      },
      'newTimeEnd': function (pickerValue) {
        if (this.newTimeStart == '' || (this.timeToMinutes(pickerValue) <= this.timeToMinutes(this.newTimeStart))) {
          this.newTimeStart = pickerValue;
        }
      }
    }
  };
</script>
  
<style scoped>
  .event-details-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  .event-details {
    text-align: left;
  }
  
  .image-wrapper {
    margin-bottom: 20px;
  }
  
  .event-image {
    width: 100%;
    height: auto;
    max-height: 500px;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .image-credit {
    font-size: 0.9em;
    color: #ffffff;
    background-color: rgba(45, 42, 42, 0.686);
    padding: 2px 5px;
    text-align: right;
  }
  
  .event-title {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .tags {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .tag {
    margin: 5px;
    padding: 5px 10px;
    background-color: #ddd;
    border-radius: 5px;
  }
  
  .info, .success, .error {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .info {
    color: #0056b3;
    background-color: #e6f2ff;
  }
  
  .success {
    color: #155724;
    background-color: #d4edda;
  }
  
  .error {
    color: #721c24;
    background-color: #f8d7da;
  }
  
  .missing {
    color: red;
  }

  .form-label {
    font-weight: bold;
    text-align: left;
    display: block;   
    margin-top: 20px;
    padding-left: 3px;
  }

  .form-check-label {
    text-align: left;
    display: block;   
    margin-top: 20px;
    padding-left: 3px;
  }

  .description p {
    margin: 0;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-check-input {
    margin-left: 0;
  }
  
  .clickable-title {
    cursor: pointer;
    color: #0056b3;
  }
  
  .clickable-title:hover {
    text-decoration: underline;
  }

  
  .form-control::placeholder {
    color: lightgrey;
    font-style: italic;   
  }

  .map-container {
    margin-top: 20px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
  }
  
  @media (max-width: 768px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
    }
  }
</style>
  