<template>
  <div class="mt-5 mt-lg-5 px-2 px-lg-5">
    <h1>Évènements retirés</h1>

    <div class="form-group">
      <label for="searchQuery">Recherche</label>
      <input type="text" v-model="searchQuery" id="searchQuery" class="form-control">
    </div>

    <div class="form-group mt-3">
      <label for="areaFilter">Filtrer par région</label>
      <select v-model="areaFilter" id="areaFilter" class="form-select">
        <option value="">Toute la France</option>
        <option v-for="area in areas" :key="area">{{ area }}</option>
      </select>
    </div>

    <p v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</p>

    <div>
      <table class="table mt-4 mb-5">
        <thead>
          <tr>
            <th @click="sortBy('title')" class="clickable">Titre
              <span v-if="sortKey === 'title'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('startDate')" class="clickable">Début
              <span v-if="sortKey === 'startDate'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('endDate')" class="clickable">Fin
              <span v-if="sortKey === 'endDate'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('createdAt')" class="clickable">Ajout
              <span v-if="sortKey === 'createdAt'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
            <th @click="sortBy('source')" class="clickable">Source
              <span v-if="sortKey === 'source'">
                <span v-if="sortDesc">&#9650;</span>
                <span v-else>&#9660;</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in removedEvents" :key="event.id" :class="{ 'pending-event': event.isPending }">
            <td class="d-flex align-items-center">
              <img :src="event.imageUrl" loading="lazy" alt="Event image" class="event-image me-3">
              <router-link :to="{ name: 'EventDetails', params: { id: event.id } }" class="event-title-link">
                <span class="event-title">{{ event.title }}</span>
              </router-link>
            </td>
            <td>{{ this.formatDate(event.startDate) }}</td>
            <td>{{ this.formatDate(event.endDate) }}</td>
            <td>{{ this.formatCreatedAt(event.createdAt) }}</td>
            <td>{{ getSource(event) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "../../axiosConfig.js";
import dateMixin from "../../mixins/dateMixin.js";
import '@fortawesome/fontawesome-free/css/all.css';

export default {
  mixins: [dateMixin],
  data() {
    return {
      events: [],
      searchQuery: '',
      sortKey: '',
      sortDesc: false,
      sortOrders: {
        title: 1,
        startDate: 1,
        endDate: 1,
        createdAt: 1,
        source: 1
      },
      errorMessage: '',
      areaFilter: ''
    };
  },
  mounted() {
    this.fetchEvents();
  },
  computed: {
    areas() {
      const uniqueAreas = new Set(this.events.map(event => event.area));
      return Array.from(uniqueAreas);
    },
    removedEvents() {
      return this.events
        .filter(event =>
          event.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .filter(event =>
          this.areaFilter ? event.area === this.areaFilter : true
        );
    }
  },
  methods: {
    fetchEvents() {
      axios.get(`events/removed`, {
        withCredentials: true
      })
        .then(response => {
          this.events = response.data;
        })
        .catch(error => {
          this.errorMessage = `Erreur: ${error.message}`;
        });
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortKey = key;
        this.sortDesc = false;
      }
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    getSource(event) {
      return event.tracker ? 'OpenData Paris' : 'Admin';
    }
  }
};
</script>

<style scoped>
.user-name {
  color: black;
  font-size: 1.2rem;
  padding-left: 15px; 
}

table img {
  width: 150px;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}

.pending-event span, .pending-event td {
  color: rgb(203, 56, 56);
}

.clickable-title,
th.clickable {
  cursor: pointer;
}

td, th {
  vertical-align: middle;
}

.d-flex.align-items-center {
  height: 100%;
}

.event-image {
  width: 150px;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}

.event-title-link {
  text-decoration: none;
}

.event-title {
  color: #333;
  font-size: 1rem;
  text-align: left;
  font-weight: bold;
  display: flex;
  align-items: center;
}
</style>
