import axios from "../axiosConfig.js";
import { createStore } from 'vuex';
import router from '@/router';

export default createStore({
  state: {
    user: null,
    userRole: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.userRole = user ? user.role : null;
    },
    logoutUser(state) {
      state.user = null;
      state.userRole = null;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const response = await axios.post('auth/login', { email, password }, { withCredentials: true });

        if (response.status === 200) {
          commit('setUser', response.data.user);
          localStorage.removeItem('logged-out');
          router.push('/evenements');
        } else {
          console.error(`Error: ${response.data.error}`);
        }
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        const response = await axios.post('auth/logout', {}, { withCredentials: true });
        
        if (response.status === 200) {
          commit('logoutUser');
          router.push('/login');
          localStorage.setItem('logged-out', 'true');
        } else {
          console.error('Logout error:', response.data.error || 'Unexpected error');
        }
      } catch (error) {
        console.error('Logout error:', error);
      }
    },
    async checkAuth({ commit }) {
      try {
        const response = await axios.get('auth/check-auth', { withCredentials: true });
        if (response.status === 200 && response.data.user) {
          commit('setUser', response.data.user);
        } else {
          commit('logoutUser');
        }
      } catch (error) {
        commit('logoutUser');
        console.error('Auth check error:', error);
      }
    }
  },
  modules: {},
});
