<template>
  <div class="event-details-container">
    <h1>Gérer les Éditeurs</h1>
    <div v-if="messageToUser" :class="messageClass">{{ messageToUser }}</div>

    <h2>Profils actifs</h2>
    <div v-if="activeEditors.length === 0">Il n'y a actuellement aucun profil actif.</div>
    <div v-else class="editor-list">
      <div class="editor-item" v-for="editor in activeEditors" :key="editor.id">
        <div>
          <router-link :to="`/profils/${editor.id}/modifier`">{{ editor.name }}</router-link>
        </div>
        <div>{{ editor.email }}</div>
        <div>{{ editor.isPending ? 'En attente' : 'Actif' }}</div>
        <div class="actions">
          <button @click="deleteEditor(editor)" class="btn btn-danger btn-sm">
            <i class="fa fa-trash"></i>
          </button>
          <button @click="resendPasswordEmail(editor)" class="btn btn-secondary btn-sm">
            <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>
    </div>

    <h2>Profils en attente</h2>
    <div v-if="pendingEditors.length === 0">Il n'y a actuellement aucun profil en attente.</div>
    <div v-else class="editor-list">
      <div class="editor-item" v-for="editor in pendingEditors" :key="editor.id">
        <div>{{ editor.name }}</div>
        <div>{{ editor.email }}</div>
        <div>{{ editor.isPending ? 'En attente' : 'Actif' }}</div>
        <div class="actions">
          <button @click="resendPasswordEmail(editor)" class="btn btn-secondary btn-sm">
            <i class="fa fa-refresh"></i>
          </button>
          <button @click="deleteEditor(editor)" class="btn btn-danger btn-sm">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="btn-group mt-5">
      <router-link to="/profils/nouveau" class="btn btn-primary mt-4">Créer un nouveau profil</router-link>
    </div>
  </div>
</template>

<script>
import axios from "../../axiosConfig.js";

export default {
  data() {
    return {
      editors: [],
      messageToUser: "",
      isError: false
    };
  },
  computed: {
    activeEditors() {
      return this.editors.filter(editor => !editor.isPending);
    },
    pendingEditors() {
      return this.editors.filter(editor => editor.isPending);
    },
    messageClass() {
      return this.isError ? 'error' : 'success';
    }
  },
  mounted() {
    this.fetchEditors();
  },
  methods: {
    fetchEditors() {
      axios
        .get(`auth/editors`, { withCredentials: true })
        .then(response => {
          this.editors = response.data;
        })
        .catch(error => {
          this.isError = true;
          this.messageToUser = `Erreur: ${error.message}`;
        });
    },
    resendPasswordEmail(editor) {
      axios
        .post(`auth/editors/${editor.id}/send-password-reset-email`, { withCredentials: true })
        .then(() => {
          this.isError = false;
          this.messageToUser = 'Email de mot de passe renvoyé avec succès.';
          setTimeout(() => {
            this.fetchEditors();
          }, 1000);
        })
        .catch(error => {
          this.isError = true;
          this.messageToUser = `Erreur: ${error.message}`;
        });
    },
    deleteEditor(editor) {
      if (confirm(`Voulez-vous supprimer ${editor.name}?`)) {
        axios
          .delete(`auth/editors/${editor.id}`, { withCredentials: true })
          .then(() => {
            this.fetchEditors();
          })
          .catch(error => {
            this.isError = true;
            this.messageToUser = `Erreur: ${error.message}`;
          });
      }
    }
  }
};
</script>

<style scoped>
.success {
  color: green;
}

.error {
  color: red;
}

.event-details-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

h1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

h2 {
  margin-top: 60px;
  margin-bottom: 20px;
}

.editor-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  align-items: center;
}

.editor-item:hover {
  background-color: #f1f1f1;
}

.actions {
  display: flex;
  gap: 10px;
}

.mt-4 {
  margin-top: 20px;
}
</style>
