import { DateTime } from 'luxon';

 export default { 
    emits: ['logout', 'loginSuccess'],

    methods: {
        //display date in words
        formatDate(unixTimestamp) {
            if (!unixTimestamp) {
                // console.warn('Date is blank or undefined.');
                return 'N/A';
            }
            const eventDate = DateTime.fromSeconds(unixTimestamp * 1).setLocale('fr');
            return eventDate.toFormat("EEE d LLL yy HH'h'mm");
        },

        convertUnixToDateTimeLocal(unixTimestamp) {
            if (unixTimestamp < 10000000) {
                console.warn('Invalid timestamp provided:', unixTimestamp);
                return '';
            }

            const date = new Date(unixTimestamp * 1000);  
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2); 
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        },

        convertGeneratedtoDateTimeLocal(date) {
            return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}T${String(date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')}`;
        },

        convertToUnixTimestamp(dateTimeLocal) {
            const date = DateTime.fromISO(dateTimeLocal, { zone: 'local' });
            return date / 1000; 
        },
        
        //retrospective date count
        formatCreatedAt(date) {
            const now = DateTime.now().setZone('utc'); // current date in UTC
            const eventDate = DateTime.fromISO(date, { zone: 'utc' }); // event date in UTC

            let diff = now.diff(eventDate, ['years', 'months', 'days']).toObject();
            if (diff.years >= 1) {
                return `le ${eventDate.day} ${this.getMonthName(eventDate.month - 1)} ${eventDate.year}`;
            }
            if (diff.months >= 1 || diff.days > 7) {
                return `le ${eventDate.day} ${this.getMonthName(eventDate.month - 1)}`;
            }
            if (diff.days == 1) {
                return "hier";
            }
            if (diff.days > 1) {
                return `il y a ${Math.ceil(diff.days)} jours`;
            }
            
            diff = now.diff(eventDate, ['hours', 'minutes']).toObject();
            if (diff.hours == 1) {
                return `il y a ${Math.floor(diff.hours)} heure`;
            }
            if (diff.hours > 1) {
                return `il y a ${Math.floor(diff.hours)} heures`;
            }
            if (diff.minutes >= 1) {
                return `il y a ${Math.floor(diff.minutes)} minutes`;
            }
            return "il y a moins d'une minute";
        },

        getMonthName(monthNumber) {
            const months = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
            return months[monthNumber];
        },
    }
};
