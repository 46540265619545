import { createApp } from 'vue';
import App from './App.vue';
import store from './store/index'; 
import router from './router';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './globalStyles/buttons.css'; 
import './globalStyles/text.css'; 


window.addEventListener('storage', event => {
  if (event.key === 'logged-out' && event.newValue === 'true') {
    if (store.state.user !== null) {
      store.dispatch('logout');
    }
  }
});

createApp(App)
  .use(store)
  .use(router)
  .mount('#app');
